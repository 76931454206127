// @flow
import * as React from 'react';
import {ClientViewOutput, useCallHistoryItemsQuery} from "../../../enrollment-types";
import Card from "../../../shared/Card";
import {Collapse, Stack, Typography} from "@mui/material";
import _ from 'lodash';
import {CallSentiment} from "./call-sentiment";
import moment from "moment/moment";

type Props = {
  client?: ClientViewOutput;
};
export const LastCallSummary = ({client}: Props) => {
  const {data, refetch, loading } = useCallHistoryItemsQuery({
    variables: {clientId: client?.id, page: {page: 0, size: 1000}},
    skip: !client?.id,
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true
  })

  const lastCall = _.orderBy(data?.callHistoryItems.data.filter(c => c.callHistoryAnalysis?.summary), 'createdAt')[0];

  return (
    <Collapse in={!!lastCall}>
      <Stack component={Card} gap={2} flex={2}>
        <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
          <div>
            <Typography color={'textPrimary'} variant={'h4'}>Last call summary</Typography>
            {lastCall && <Typography color={'textSecondary'} variant={'body2'}>
              {moment(lastCall.createdAt).format('L LT')}
            </Typography>}
          </div>
          <CallSentiment sentiment={lastCall?.callHistoryAnalysis?.sentiment} size={'small'} />
        </Stack>
        <Typography sx={{whiteSpace: 'pre-line'}}>{lastCall?.callHistoryAnalysis?.summary}</Typography>
      </Stack>
    </Collapse>
  );
};