import moment from "moment/moment";
import React from "react";
import {gql, useSubscription} from "@apollo/client";
import {PageableSlaOutput} from "../enrollment-types";
import useInterval from "../shared/useInterval";
import * as _ from "lodash";
import {calcDurationToNow} from "../shared/tools";
import {Box, Typography} from "@mui/material";

const TwoHrCallbackSubscription = `
  subscription {
    slas (pageInput: {page: 0, size: 100}, filter: {within2Hours: true, range: {from: "${moment.utc().startOf('month').toISOString()}", to: "${moment().utc().add(1, 'week').toISOString()}"}}) {
      data {
        createdAt
      }
      totalElements
    }
  }
`


const TwoHrCallback = () => {
  const [time, setTime] = React.useState<string>();

  const {data} = useSubscription<{slas: PageableSlaOutput}>(gql(TwoHrCallbackSubscription));

  useInterval(() => {
    const oldestTime = _.min(data?.slas?.data?.map(s => s.createdAt))
    setTime(calcDurationToNow(oldestTime));
  }, 1000);

  const total = React.useMemo(() => {
    return data?.slas?.totalElements ? parseFloat(data?.slas?.totalElements) : 0
  }, [data?.slas?.totalElements])

  if (!total) {
    return null
  }

  return <>
    {!!total && <Box display={'flex'} justifyContent={'center'} alignItems={'center'} sx={{backgroundColor: '#f1db40'}} p={'10px 16px'}>
      <Typography color={'textPrimary'} className={'medium'}>{data?.slas?.totalElements} Leads Due for 2hr Callback. Most Overdue:  {time}</Typography>
    </Box>}
  </>
}

export default TwoHrCallback
