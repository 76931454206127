import {DrugOutput} from "../../../../types";
import {createStyles, makeStyles} from "@mui/styles";
import React, {useState} from "react";
import * as _ from "lodash";
import {
  CircularProgress, IconButton,
  ListItem,
  ListItemAvatar, ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
  Typography
} from "@mui/material";

interface DrugRowProps {
  drug: DrugOutput & {notSupported: boolean},
  n?: number,
  onEditClick: () => void,
  onDeleteClick: () => void,
}

const useRowStyles = makeStyles(() =>
  createStyles({
    listItem: {
      alignItems: 'flex-start'
    },
    avatar: {
      minWidth: 20,
      marginTop: 4
    }
  }),
);

export const DrugRow = (props: DrugRowProps) => {
  const classes = useRowStyles();
  const [loading, setLoading] = useState(false)

  const title = props?.drug?.packName ? props?.drug?.packName + (props.drug.notSupported ? ' (not supported)' : '') : '';

  let subtitle = props?.drug?.packRxcui ? ` rxcui: ${props?.drug?.packRxcui}` : '';

  subtitle += ', Quantity: ' + props.drug?.quantity + ' ' + _.lowerCase(props.drug?.frequency);

  const handleDeleteClick = () => {
    setLoading(true)
    props.onDeleteClick()
  }

  const disabled = loading || props.drug.notSupported;

  return <ListItemButton onClick={!props.drug.notSupported ? props.onEditClick : undefined}
                         dense disableGutters className={classes.listItem} sx={{opacity: disabled ? .5 : 1}}>
    <ListItemText
      primary={title}
      secondary={
        <Typography color={'textSecondary'}>{subtitle}</Typography>
      }
    />
    <ListItemSecondaryAction>
      {loading && <CircularProgress size={20} sx={{opacity: .5}} />}
      {!loading && (
        <IconButton size={'small'} className={'ml-8'} onClick={handleDeleteClick}><img src={'/img/delete-icon.svg'}/></IconButton>
      )}
    </ListItemSecondaryAction>
  </ListItemButton>
};