// @flow
import * as React from 'react';
import {Box, Chip, Divider, Stack, Typography} from "@mui/material";
import {
  CallHistoryItemsQuery,
  ClientViewOutput, useCallHistoryItemsQuery
} from "../../../../enrollment-types";
import {useEffect, useState} from "react";
import {CallsList} from "./ui/calls-list";
import {CallSentiment} from "../call-sentiment";
import Card from "../../../../shared/Card";
import moment from "moment";
import {getUserName} from "../../../../shared/tools";
import _ from 'lodash';

type Props = {
  client?: ClientViewOutput;
};

export const CallHistory = ({client}: Props) => {
  const [tab, setTab] = useState('key_topics');
  const [call, setCall] = useState<CallHistoryItemsQuery['callHistoryItems']['data'][0] | undefined>()

  const {data, refetch, loading } = useCallHistoryItemsQuery({
    variables: {clientId: client?.id, page: {page: 0, size: 1000}},
    skip: !client?.id,
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true
  })

  const calls = data?.callHistoryItems.data;

  useEffect(() => {
    calls?.length && setCall(prev => !prev ? calls[0] : prev);
  }, [calls]);

  return (
    <Stack direction={'row'} gap={3}>
      <Stack component={Card} gap={3} flex={1}>
        <div>
          <Typography color={'textPrimary'} variant={'h4'}>Call analysis</Typography>
          {call && <Typography color={'textSecondary'} variant={'body2'}>
            {moment(call.createdAt).format('L LT')}
          </Typography>}
        </div>

        <Stack gap={1}>
          <Typography color={'textPrimary'} variant={'h5'}>Key Topics</Typography>
          {call && !call.callHistoryAnalysis?.topics ? <NoData/> : <Typography>
            {call?.callHistoryAnalysis?.topics?.map(_.upperFirst).join(', ')}
          </Typography>}
        </Stack>

        <Stack gap={1}>
          <Typography color={'textPrimary'} variant={'h5'}>
            AI Call Summary  {call?.agent ? `(${getUserName(call.agent)} and ${client ? getUserName(client) : ''}` : ''})
          </Typography>
          {call && !call.callHistoryAnalysis?.summary ? <NoData/> : <Typography sx={{whiteSpace: 'pre-line'}}>
            {call?.callHistoryAnalysis?.summary}
          </Typography>}
        </Stack>

        <Stack gap={1.5}>
          <Typography color={'textPrimary'} variant={'h5'}>
            On-Call Sentiment Analysis
          </Typography>
          {call?.callHistoryAnalysis?.sentiment ? <div>
            <CallSentiment sentiment={call?.callHistoryAnalysis?.sentiment}/>
          </div> : <NoData/>}

        </Stack>
      </Stack>

      <Divider orientation={'vertical'}/>

      <CallsList client={client} onChange={refetch} calls={calls} loading={loading} active={call} onClick={setCall}/>

    </Stack>
  );
};


const NoData = () => (
  <Box sx={{height: 100, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
    <Typography><b>No data</b></Typography>
  </Box>
)

